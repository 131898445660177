//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [{ icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" }],
    dataInici: 0,
    horaInici: 0,
    tempsInvertit: 0,
    bReadOnly: true,
  }),
  mounted() {
    this.getTask();
  },
  methods: {
    goBack() {
      this.$router.push("/workdaysummary");
    },
    goMenu(action) {
      switch (action) {
        case "logout":
          this.goLogOut();
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    getTask() {
      var self = this;
      self.bReadOnly = true;
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio01_getitembydoc", {
          token: sessionStorage.getItem("Token"),
          checkUpdate: true,
          docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: parseInt(sessionStorage["sessDocLineId"]),
        })
        .then((response) => {
          if (response.error == null) {
            self.bReadOnly = false;
            self.dataInici = response.data.docDate;
            self.horaInici = response.data.docTime;
            self.tempsInvertit = response.data.calcMinutes;
            if (response.data.calcMinutes == 0) { self.tempsInvertit = "00:00"; } else { self.tempsInvertit = response.data.calcMinutes; }
          } else {
            self.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          self.msgAlert(error.response, true);
        });
    },
    MinutsAdd(q) {
      function D(J) { return (J < 10 ? '0' : '') + J; }
      var piece = this.tempsInvertit.split(':');
      var mins = piece[0] * 60 + +piece[1] + +q;
      if (mins < 0) { mins = 0 }
      this.tempsInvertit = D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
    },
    dialogTimeSaveChanges() {
      var self = this;
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio01_updatetime", {
          token: sessionStorage.getItem("Token"),
          docCompanyId: parseInt(sessionStorage["sessDocCompanyId"]),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docSerialId: parseInt(sessionStorage["sessDocSerialId"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: parseInt(sessionStorage["sessDocLineId"]),
          userId: parseInt(sessionStorage.getItem("UserId")),
          docDate: self.dataInici,
          timeStart: self.horaInici,
          timeDuration: self.tempsInvertit,
        })
        .then(() => {
          self.goBack();
        })
        .catch(function (error) {
          self.msgAlert(error.response, true);
        });
    },
  },
  msgAlert(missatge, close) {
    var onClose = () => {
      if (close && close == true) {
        this.$router.push("/");
      }
    };

    this.$alert.show({ message: missatge, onClose: onClose });
  },
};
